@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  scroll-behavior: smooth;
  --fontFamily: "Poppins", sans-serif;
  --fontWeightRegular: 400;
  --fontWeightMedium: 500;
  --fontWeightSemiBold: 600;
  --fontWeightBold: 700;
  --fontWeightExtraBold: 800;
  --fontWeightBlack: 900;
  --fontStyleNormal: normal;
  --fontStyleItalic: italic;
  --blackColor: #000;
  --blackBeanColor: #0c0c0c;
  --charcoalColor: #1B1B1B;
  --darkGrayColor: #a9a9a9;
  --lightBlackColor: #1C1C1E;
  --darkBlackColor: #111111;
  --whiteColor: #FFFFFF;
  --darkBlueColor: #008DBF;
  --darkBlueColor2: #0e2a34;
  --lightBlueColor: #e3f3fe;
  --diffrerentBlack: #10171a;
  --layoutTheme: #191919;
  --layoutSiderMenu: #23211e;
  --defaultRedcolor: #FF4747;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/* Side Scroller */
::-webkit-scrollbar {
  height: 9px;
  width: 8px
}

::-webkit-scrollbar-track {
  background: var(--blackBeanColor)
}

::-webkit-scrollbar-thumb {
  background: var(--lightBlackColor);
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--darkBlueColor)
}

h1,
h2,
h3,
h4,
h5,
h6,
label {
  color: var(--whiteColor) !important;
}



/* =========================================================== ANTD GLOBAL =========================================================== */
.ant-popover .ant-popover-inner {
  background-color: var(--blackBeanColor);
  padding: 0px;
}

.ant-popover-title {
  color: var(--whiteColor) !important;
  background-color: var(--charcoalColor);
  text-align: center;
  padding: 0.7rem 0.5rem !important;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  -webkit-border-radius: 0.5rem 0.5rem 0rem 0rem;
  -moz-border-radius: 0.5rem 0.5rem 0rem 0rem;
  -ms-border-radius: 0.5rem 0.5rem 0rem 0rem;
  -o-border-radius: 0.5rem 0.5rem 0rem 0rem;
  font-size: 1rem;
}

.ant-popover {
  max-width: 25rem;
}

/* Popover End */

.ant-layout {
  font-family: var(--fontFamily) !important;
  font-weight: 600;
}

header.ant-layout-header.css-dev-only-do-not-override-1rqnfsa {
  background: var(--whiteColor);
}

li.ant-menu-item.ant-menu-item-only-child {
  color: var(--blackColor) !important;
  background: var(--diffrerentBlack);
  height: 3rem;
  margin: 2px 0px !important;
}

li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
  color: var(--whiteColor) !important;
  /* border-left: 5px solid #73C2FB; */
  background: var(--darkBlueColor2);
  font-weight: bold;
}

.ant-layout .ant-layout-sider {
  background: var(--darkBlackColor);
}

.ant-menu-inline .ant-menu-item {
  height: 40px;
  line-height: 25px;
  list-style-position: inside;
  list-style-type: disc;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.ant-menu .ant-menu-title-content {
  transition: color 0.3s;
  align-items: center;
  display: flex;
}

.ant-menu-title-content span {
  color: var(--whiteColor) !important;
}

.ant-layout-sider-trigger {
  display: none;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  background-color: var(--darkBlueColor);
  border: 1px dashed var(--lightBlueColor);
}

.ant-select-selection-placeholder {
  color: var(--lightBlueColor) !important;
}

.jodit-container:not(.jodit_inline) {
  border: 1px solid var(--darkBlackColor) !important;
}

.jodit-wysiwyg {
  border-radius: px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.jodit-status-bar {
  display: none !important;
}

.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
  background: var(--blackColor);
  border-width: 1px;
  border-style: solid;
  border-color: var(--defaultRedcolor);
}

.input__ {
  background: var(--darkBlackColor) !important;
  color: var(--whiteColor) !important;
}

.antd-input input::placeholder {
  /* color: var(--whiteColor) !important; */
}

.ant-picker-filled {
  background: var(--darkBlackColor) !important;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  color: var(--whiteColor);
}

/* For TimePicker placeholder */
.ant-picker-input>input::placeholder {
  color: gray !important;
}

.ant-input-outlined {
  border-width: 1px;
  border-style: solid;
}

.ant-input::placeholder {
  color: lightgray !important;
}

.ant-select-selection-placeholder {
  color: lightgray !important;
}

.ant-input-outlined,
.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):hover {
  border-width: 1px;
  border-style: solid;
  border-color: var(--darkBlackColor);
}

.ant-select-borderless.ant-select-multiple .ant-select-selection-item {
  background-color: var(--charcoalColor);
  border: 1px solid transparent;
}

.ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item-remove {
  color: var(--whiteColor);
}

.ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item-remove:hover {
  color: var(--defaultRedcolor);
}

span.ant-select-selection-item {
  display: flex;
  text-align: center;
  align-items: center;
}

.ant-select-selection-item {
  color: var(--whiteColor) !important;
}

.ant-select-dropdown {
  color: var(--whiteColor) !important;
  background-color: var(--darkBlackColor);
}

.ant-input-outlined:hover {
  border-color: var(--darkBlackColor);
  background-color: var(--whiteColor);
}

.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
  border-width: 1px;
  border-style: solid;
  border-color: var(--defaultRedcolor);
}

.ant-picker-outlined.ant-picker-status-error:not(.ant-picker-disabled) {
  border-width: 1px;
  border-style: solid;
  border-color: var(--defaultRedcolor);
}

.ant-input-affix-wrapper>input.ant-input {
  padding: 0;
  font-size: inherit;
  border: none;
  border-radius: 0;
  outline: none;
}

.ant-picker-outlined {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: var(--darkBlackColor) !important;
}

.ant-select-selector {
  border: none !important;
  min-height: 2.9rem;
  background-color: var(--lightBlackColor) !important;
}

.ant-select-single {
  min-height: 2.5rem;
  text-align: left;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
  width: 8rem !important;
  height: 8rem !important;
  margin-inline-end: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  border: 1px dashed var(--darkBlueColor);
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item {
  width: 8rem !important;
  height: 8rem !important;
}

/* Modal Start */
.ant-modal {
  border-radius: 20px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}

.ant-modal .ant-modal-content {
  background-color: rgb(22, 22, 22)
}

.ant-modal-root .ant-modal-wrap {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.ant-collapse-content-box {
  background-color: var(--darkBlackColor) !important;
  border-radius: 0.4rem;
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  -ms-border-radius: 0.4rem;
  -o-border-radius: 0.4rem;
}

.pac-item>.pac-item-query>.pac-matched {
  color: var(--darkBlackColor) !important;
}

.pac-item>span {
  color: var(--darkBlackColor) !important;
}

/* Modal End */

input {
  min-height: 2.5rem;
  background-color: var(--darkBlackColor) !important;
  background: var(--darkBlackColor) !important;
}

.ant-switch.ant-switch-checked {
  background: var(--darkBlueColor) !important;
}

.ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  color: var(--whiteColor);
  background-color: var(--darkBlueColor);
  border-color: var(--darkBlueColor);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding-inline: 15px;
  padding-block: 0;
  font-size: 14px;
  line-height: 30px;
  background: transparent !important;
  border: transparent !important;
}

/* Jodit Editor */
.jodit-toolbar__box:not(:empty) {
  border-bottom: transparent !important;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.ant-layout .ant-layout-header {
  background-color: var(--darkBlackColor);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

form {
  width: 75%;
}

.darkSelect .ant-select-selector {
  background: var(--darkBlackColor) !important;
  width: 100% !important;
  color: var(--whiteColor) !important;
}

.ant-select-dropdown .ant-select-item {
  color: var(--whiteColor) !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--lightBlackColor) !important;
}

.ant-collapse {
  border: transparent;
}

.ant-collapse>.ant-collapse-item {
  border-bottom: none !important;
}

.ant-collapse .ant-collapse-content {
  background-color: var(--charcoalColor);
  border-top: none !important;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 12px 13px;
}

/* TextArea Start */
.ant-input-textarea-affix-wrapper.ant-input-affix-wrapper>textarea.ant-input {
  color: var(--whiteColor) !important;
  background-color: var(--lightBlackColor) !important;
}

.ant-input-textarea-affix-wrapper.ant-input-affix-wrapper>textarea.ant-input::placeholder {
  color: var(--darkGrayColor) !important;
}

.ant-input-textarea-affix-wrapper.ant-input-affix-wrapper {
  background-color: var(--lightBlackColor) !important;
  outline: none !important;
  border: none !important;
  box-shadow: rgba(44, 44, 44, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.ant-image .ant-image-img {
  width: auto !important;
}

/* TextArea End */

.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item {
  border: none !important;
}

.ant-result-title {
  color: var(--whiteColor) !important;
}

.ant-result-subtitle {
  color: var(--whiteColor) !important;
  font: 1.5em sans-serif !important;
}

.ant-pagination-item {
  color: var(--whiteColor) !important;
}

.ant-picker .ant-picker-input>input[disabled] {
  color: var(--whiteColor) !important;
  cursor: text !important;
}

/* =========================================================== LOCALE VARIABLES =========================================================== */
.lgHalfWidth {
  width: 50%;
}

.searchField>input {
  background-color: transparent !important;
  outline: none;
  border: none;
  padding-inline: 1rem;
}

.datePicker {
  color: var(--darkGrayColor);
}

.datePicker ::placeholder {
  color: var(--darkGrayColor) !important;
}

.blackBg {
  background-color: var(--blackColor);
  padding: 1rem;
}

.lightBlackBg {
  background-color: var(--lightBlackColor);
  padding: 1rem;
}

.darkBlackBg {
  background-color: var(--darkBlackColor);
  padding: 1rem;
}

.charcoalBg {
  background-color: var(--charcoalColor);
  padding: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 29px 0px;
}

.fontWeightBold {
  font-weight: var(--fontWeightBold) !important;
}

.reactIcons {
  color: var(--darkBlueColor);
  font-size: 3rem;
}

a {
  color: inherit;
  text-decoration: none !important;
}

.blueText {
  color: var(--darkBlueColor) !important;
}

.subscriptionPadding {
  padding-inline: 5rem;
}

.buttonBlueText>span {
  color: var(--darkBlueColor) !important;
}

.commonButton {
  background-color: var(--darkBlueColor);
  min-height: 2.8rem;
  min-width: 8rem;
  font-weight: var(--fontWeightMedium);
  color: var(--whiteColor);
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  text-transform: capitalize;
}

.commonRedButton {
  background-color: var(--defaultRedcolor);
  min-height: 2.8rem;
  min-width: 8rem;
  font-weight: var(--fontWeightMedium);
  color: var(--whiteColor);
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  text-transform: uppercase;
}

.commonOutlineButton {
  background-color: var(--charcoalColor);
  min-height: 2.8rem;
  border: 2px solid var(--darkBlueColor);
  min-width: 8rem;
  font-weight: var(--fontWeightMedium);
  color: var(--whiteColor);
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  text-transform: capitalize;
}

.commonButtonMinWidth {
  min-width: 13rem;
}

.inputFormShadow {
  box-shadow: 0px 4px 14px 0px #73C2FB40;

}

.ant-select-selector input {
  color: var(--whiteColor) !important;
}

.italicText {
  font-style: var(--fontStyleItalic);
}

.centerContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp_container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2rem 0 0 0;
}

.otp_container input {
  color: var(--darkBlueColor);
  font-weight: var(--fontWeightMedium);
}

.otp_input_container {
  padding: 1rem;
  margin: 0.8rem;
  font-size: 2rem;
  border-radius: 0.5rem;
  width: 4rem !important;
  height: 4rem !important;
  border: none !important;
  margin-top: 0rem !important;
  border-color: none !important;
}

.commonBoxShadow {
  box-shadow: 0px 4px 15px 0px #00000026;
}

.customSiderWidth {
  width: 270px !important;
  min-width: 270px !important;
  max-width: 270px !important;
  flex: 0 0 270px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.br10 {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.bgMedia {
  background: url('./Assets/bgMedia.png');
  min-height: 100vh;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 100vw;
  background-size: cover;
  background-color: var(--blackColor);
}

.bgMediaGetStart {
  background: url('./Assets/getStartedBg.png');
  min-height: 100vh;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 100vw;
  background-size: cover;
  background-color: var(--blackColor);
}

.bgMediaLogin {
  background: url('./Assets/loginBg.png');
  min-height: 100vh;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 100vw;
  background-size: cover;
  background-color: var(--blackColor);
}

.bgMediaForgot {
  background: url('./Assets/forgotBg.png');
  min-height: 100vh;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 100vw;
  background-size: cover;
  background-color: var(--blackColor);
}

.bgMediaVerify {
  background: url('./Assets/verifyBg.png');
  min-height: 100vh;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 100vw;
  background-size: cover;
  background-color: var(--blackColor);
}

.bgMediaNewPass {
  background: url('./Assets/newPassBg.png');
  min-height: 100vh;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 100vw;
  background-size: cover;
  background-color: var(--blackColor);
}

.logutBtn {
  background-color: var(--darkBlueColor);
  width: 100%;
  border: none;
  height: 45px;
  font-size: 1.1rem;
  border-radius: 0;
}

.heading_font {
  font-size: 1.3rem;
  color: var(--whiteColor);
}

.my_cards {
  border-radius: 9px;
}

.service_search .ant-input-affix-wrapper {
  background-color: var(--lightBlackColor) !important;
}

.service_search .ant-input-affix-wrapper>input.ant-input {
  background-color: var(--lightBlackColor) !important;
}

/* .my_cards .ant-checkbox .ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border-radius: 100% !important;
  border: 1px solid var(--darkBlueColor) !important;
  background-color: transparent !important;
} */
.ant-checkbox .ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border-radius: 100% !important;
  border: 1px solid var(--darkBlueColor) !important;
  background-color: transparent !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border-radius: 50%;
  border: 1px solid var(--darkBlueColor) !important;
  height: 10px;
  width: 10px;
  margin-top: 2px;
  margin-left: -1px;
  background-color: var(--darkBlueColor);
  /* Change the background color when checked */
}

.ant-radio-wrapper .ant-radio-inner {
  background-color: var(--charcoalColor);
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: var(--charcoalColor);
}

.ant-radio-wrapper .ant-radio-inner::after {
  background-color: var(--darkBlueColor);
  height: 22px;
  width: 22px;
  margin-top: -11px;
  margin-left: -11px;
}


#date {
  background-color: var(--lightBlackColor) !important;
}

#date::placeholder {
  color: var(--whiteColor);
}

#time {
  background-color: var(--lightBlackColor) !important;
}

#signature div input {
  background-color: var(--blackBeanColor) !important;
}

.date_timeDiv svg {
  color: var(--darkBlueColor);
}

.home_image.ant-image-img {
  width: 100% !important;
}

.ant-picker-cell.ant-picker-cell-in-view {
  height: 4rem !important;
}

.ant-picker-dropdown .ant-picker-panel-container {
  color: var(--whiteColor) !important;
  background-color: var(--darkBlackColor);
}

.ant-picker-header {
  color: var(--whiteColor) !important;
}

.ant-picker-dropdown .ant-picker-header>button {
  color: var(--whiteColor) !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view {
  color: var(--whiteColor) !important;
}

.ant-picker-dropdown .ant-picker-date-panel .ant-picker-body {
  padding: 0px !important;
}

.ant-picker-dropdown .ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  color: var(--whiteColor) !important;
}

.ant-picker-dropdown .ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: var(--blackBeanColor) !important;
}

.ant-picker-calendar .ant-picker-panel {
  color: var(--whiteColor) !important;
  background-color: var(--darkBlackColor);
}

.ant-picker-calendar {
  color: var(--whiteColor) !important;
  background-color: var(--darkBlackColor);
}

.ant-picker-calendar .ant-picker-cell-in-view {
  color: var(--whiteColor) !important;
}

.ant-picker-content thead {
  background-color: var(--darkBlueColor);
  height: 2.5rem;
  border-radius: 0.5rem !important;
  -webkit-border-radius: 0.5rem !important;
  -moz-border-radius: 0.5rem !important;
  -ms-border-radius: 0.5rem !important;
  -o-border-radius: 0.5rem !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
  background-color: var(--darkBlueColor);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.ant-picker-content thead tr th {
  color: var(--whiteColor) !important;
  font-weight: 800 !important;
}

.ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
  background-color: var(--darkBlueColor);
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-today {
  color: var(--darkBlueColor) !important;
}

.spotifyButton a span:hover {
  color: var(--whiteColor) !important;
}

.selectHelper>* .ant-form-item-explain-error {
  margin-top: 0.5rem;
}

@media (max-width: 992px) {
  .customSiderWidth {
    width: 75px !important;
    min-width: 75px !important;
    max-width: 75px !important;
    flex: 0 0 75px !important;
  }

  .ant-layout-header {
    padding-inline: 10px !important;
  }

  .lgHalfWidth {
    width: 95%;
  }

  form {
    width: 95%;
  }

  .subscriptionPadding {
    padding-inline: 1rem;
  }
}

.selectPressure .ant-select-arrow {
  color: #fff !important;
}

.addServiceInput {
  background-color: var(--blackColor);
}

.MuiRating-iconEmpty{
  color: var(--lightBlueColor) !important;
}

.MuiRating-iconFilled {
  color: #ffc602 !important;
}

.ant-badge .ant-badge-dot{
  box-shadow: none !important;
}

.ant-badge .ant-badge-count{
  box-shadow: none !important;
}

.ant-slider .ant-slider-rail{
  background-color: var(--whiteColor)!important;
}

td.ant-picker-cell.ant-picker-cell-disabled.ant-picker-cell-in-view {
  color: var(--defaultRedcolor) !important;
}